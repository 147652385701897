import { Checkbox } from 'antd'
import type { FC } from 'react'
import { useCallback } from 'react'

import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import type { IDefaultFilterProps } from '../0_FilteraCollection/Filters.model'
import { SFilterBlock, STitle } from '../Filters.styled'

const FILTER_PAYMENT_FROM_PFDO = 3 // Оплата в рамках системы ПФДО
const PAYMENT_FROM_PFDO_NAME =
  'Реестр государственных (муниципальных) услуг в социальной сфере'

export const ServicesInSocialSphere: FC<IDefaultFilterProps> = ({
  setFilters,
  prevState,
}) => {
  const handleOnChange = useCallback(() => {
    if (!prevState?.value)
      setFilters(QueryKeyENUM.kind, {
        value: FILTER_PAYMENT_FROM_PFDO,
        name: PAYMENT_FROM_PFDO_NAME,
      })
    else setFilters(QueryKeyENUM.kind, null)
  }, [prevState?.value, setFilters])

  return (
    <SFilterBlock>
      <STitle>{PAYMENT_FROM_PFDO_NAME}</STitle>
      <Checkbox checked={!!prevState?.value} onChange={handleOnChange} />
    </SFilterBlock>
  )
}
