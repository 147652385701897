import { Spin } from 'antd'
import { DEFAULT_FIRST_ITEM_SLICE, DEFAULT_LAST_ITEM_SLICE } from 'numberConsts'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { filtersAPI } from '../../../axios/axios'
import type { RootState } from '../../../store/store'
import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import type { IFilterProps, ItemType } from '../ApplyFilters.model'
import { FilterItem } from '../components/FilterItem/FilterItem'
import {
  SFilterBlock,
  SFilterList,
  SHeaderWrapp,
  STitle,
  SUnshortTitle,
} from '../Filters.styled'

export const ProfileProgramFilter: FC<IFilterProps> = ({
  setFilters,
  prevState,
}) => {
  const [fetch, setFetch] = useState(false)
  const [shortContent, setShortContent] = useState(true)
  const [profiles, setProfiles] = useState<ItemType[]>([])
  const [selectedProfile, setProfile] = useState<ItemType | null>(prevState)

  const { filters } = useSelector((state: RootState) => state)

  const chosenDirectionId = filters.programsFilters?.direction_id?.id || null

  const handleSetFetch = useCallback((value: boolean) => {
    setFetch(value)
  }, [])

  const handleSetShort = useCallback(() => {
    setShortContent((prev) => !prev)
  }, [])

  const handleSetItem = useCallback(
    (item: ItemType) => {
      if (selectedProfile?.id === item.id) {
        setFilters(QueryKeyENUM.profileId, null)
        setProfile(null)
      } else {
        setProfile(item)
        setFilters(QueryKeyENUM.profileId, { ...item, value: item.id })
      }
    },
    [selectedProfile, setFilters],
  )

  const content = useMemo(() => {
    if (fetch) {
      return <Spin />
    }
    if (profiles && !chosenDirectionId) {
      const visibleProfiles = shortContent
        ? profiles.slice(DEFAULT_FIRST_ITEM_SLICE, DEFAULT_LAST_ITEM_SLICE)
        : profiles
      return visibleProfiles.map((profile) => (
        <FilterItem
          key={profile.id}
          item={profile}
          setItem={handleSetItem}
          active={selectedProfile?.id === profile.id}
        />
      ))
    }
    if (profiles && chosenDirectionId) {
      const profilesByChosenDirection = profiles.filter(
        (profile) => profile.direction === chosenDirectionId,
      )
      const visibleProfiles = shortContent
        ? profilesByChosenDirection.slice(0, 10)
        : profilesByChosenDirection
      return visibleProfiles.map((profile) => (
        <FilterItem
          key={profile.id}
          item={profile}
          setItem={handleSetItem}
          active={selectedProfile?.id === profile.id}
        />
      ))
    }
    return []
  }, [
    chosenDirectionId,
    fetch,
    handleSetItem,
    profiles,
    selectedProfile?.id,
    shortContent,
  ])

  const getProfiles = useCallback(async () => {
    try {
      handleSetFetch(true)
      const res = await filtersAPI.getProfiles()
      setProfiles(res.data.data)
      handleSetFetch(false)
    } catch (e) {
      console.error(e)
    }
  }, [handleSetFetch])

  useEffect(() => {
    getProfiles()
  }, [getProfiles])

  useEffect(() => {
    !prevState && setProfile(prevState)
  }, [prevState])

  return (
    <SFilterBlock>
      <SHeaderWrapp>
        <STitle>Профили</STitle>
        <SUnshortTitle onClick={handleSetShort}>
          {shortContent ? 'Показать все' : 'Скрыть'}
        </SUnshortTitle>
      </SHeaderWrapp>
      <SFilterList>{content}</SFilterList>
    </SFilterBlock>
  )
}
