import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import type { IFilterProps, ItemType } from '../ApplyFilters.model'
import { FilterItem } from '../components/FilterItem/FilterItem'
import { SFilterBlock, SFilterList, STitle } from '../Filters.styled'

export const Duration = [
  { name: 'До 13 часов', id: '-13' },
  { name: 'с 13 до 36 часов', id: '13-36' },
  { name: 'с 37 до 72 часов', id: '37-72' },
  { name: '73 и более часов', id: '73+' },
]

export const DurationFilter: FC<IFilterProps> = ({ setFilters, prevState }) => {
  const [selectedDuration, setSelectedDuration] = useState<ItemType | null>(
    prevState,
  )

  const handleSetItem = useCallback(
    (item: ItemType) => {
      if (selectedDuration?.id === item.id) {
        setFilters(QueryKeyENUM.programDurationInHours, null)
        setSelectedDuration(null)
      } else {
        setSelectedDuration(item)
        setFilters(QueryKeyENUM.programDurationInHours, {
          ...item,
          value: item.id,
        })
      }
    },
    [selectedDuration, setFilters],
  )

  const content = useMemo(
    () =>
      Duration.map((d) => (
        <FilterItem
          key={d.id}
          item={d}
          setItem={handleSetItem}
          active={selectedDuration?.id === d.id}
        />
      )),
    [handleSetItem, selectedDuration],
  )

  useEffect(() => {
    !prevState && setSelectedDuration(prevState)
  }, [prevState])

  return (
    <SFilterBlock>
      <STitle>Продолжительность</STitle>
      <SFilterList>{content}</SFilterList>
    </SFilterBlock>
  )
}
