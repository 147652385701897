import styled from 'styled-components'

import { styleMixins } from '../../../style'

const { getSpacing } = styleMixins.offset

export const SFiltersWrapp = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(4)};
  max-width: 100%;
  width: 336px;
  padding-right: ${getSpacing(2)};
`
