import { Spin } from 'antd'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { filtersAPI } from '../../../axios/axios'
import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import type { IDefaultFilterProps } from '../0_FilteraCollection/Filters.model'
import type { ItemType } from '../ApplyFilters.model'
import { FilterItem } from '../components/FilterItem/FilterItem'
import { SFilterBlock, SFilterList, STitle } from '../Filters.styled'

export const DirectionsFilter: FC<IDefaultFilterProps> = ({
  setFilters,
  prevState,
  queryKey = QueryKeyENUM.directionId,
}) => {
  const [directions, setDirections] = useState<ItemType[]>([])
  const [fetch, setFetch] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ItemType | null>(prevState)

  const handleSetFetch = useCallback((value: boolean) => {
    setFetch(value)
  }, [])

  const handleSetItem = useCallback(
    (item: ItemType) => {
      if (selectedItem?.id === item.id) {
        setFilters(queryKey, null)
        setSelectedItem(null)
      } else {
        setSelectedItem(item)
        setFilters(queryKey, { ...item, value: item.id })
      }
    },
    [queryKey, selectedItem?.id, setFilters],
  )

  const content = useMemo(() => {
    if (fetch) {
      return <Spin />
    }
    if (!fetch && directions) {
      return directions.map((direction) => (
        <FilterItem
          key={direction.id}
          item={direction}
          setItem={handleSetItem}
          /* @ts-ignore */
          active={selectedItem?.id === direction.id}
        />
      ))
    }
    return []
  }, [directions, fetch, handleSetItem, selectedItem?.id])

  const getDirections = useCallback(async () => {
    try {
      handleSetFetch(true)
      const res = await filtersAPI.getDirections()
      setDirections(res.data.data)
      handleSetFetch(false)
    } catch (e) {
      console.error(e)
    }
  }, [handleSetFetch])

  useEffect(() => {
    getDirections()
  }, [getDirections])

  useEffect(() => {
    !prevState && setSelectedItem(prevState)
  }, [prevState])

  return (
    <SFilterBlock>
      <STitle>Направленность</STitle>
      <SFilterList>{content}</SFilterList>
    </SFilterBlock>
  )
}
