import { Select } from 'antd'
import type { FC } from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { filtersAPI } from '../../../axios/axios'
import type { RootState } from '../../../store/store'
import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import { SFilterBlock, STitle } from '../../atoms/Filters/Filters.styled'
import type { IFilterProps, ItemType } from '../ApplyFilters.model'
import type { IMunicipality } from './MunicipalityFilter.model'

export const MunicipalityFilter: FC<IFilterProps> = ({
  setFilters,
  prevState,
}) => {
  const { userLocation } = useSelector((state: RootState) => state.user)

  const abortControllerRef = useRef<null | AbortController>(null)
  const [filteredMunicipality, setFilteredMunicipality] = useState<
    IMunicipality[]
  >([])
  const [selectedMunicipality, setSelectedMunicipality] =
    useState<ItemType | null>(prevState)

  const isSelectedMunicipality =
    selectedMunicipality?.name ?? userLocation?.name

  const getOptions = useCallback(async (searchString: string) => {
    if (searchString) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
        abortControllerRef.current = null
      }

      abortControllerRef.current = new AbortController()

      try {
        const res = await filtersAPI.getMO(searchString, {
          signal: abortControllerRef.current?.signal,
        })
        const { data } = res.data

        setFilteredMunicipality(data)
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  const handleOnChange = useCallback(
    (moId: number) => {
      if (!Number.isInteger(moId)) {
        setFilters(QueryKeyENUM.moId, null)
        setSelectedMunicipality(null)
      } else {
        const foundMunicipality = filteredMunicipality.find(
          (municipality) => municipality.id === moId,
        )
        const munObj = foundMunicipality
          ? {
              guid: foundMunicipality.GUID,
              name: foundMunicipality.name,
              value: foundMunicipality.id,
            }
          : null

        setSelectedMunicipality(munObj)
        setFilters(QueryKeyENUM.moId, munObj)
      }
    },
    [filteredMunicipality, setFilters],
  )

  const options = useMemo(() => {
    if (filteredMunicipality.length) {
      return filteredMunicipality.map((municipality) => ({
        value: municipality.id,
        label: municipality.name,
      }))
    }
    return []
  }, [filteredMunicipality])

  return (
    <SFilterBlock>
      <STitle>Муниципальный район</STitle>
      <Select
        defaultValue={isSelectedMunicipality}
        allowClear
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={getOptions}
        notFoundContent={null}
        placeholder="Начните вводить название"
        onChange={handleOnChange}
        options={options}
      />
    </SFilterBlock>
  )
}
