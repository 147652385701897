import styled from 'styled-components'

import { getSpacing } from '../../../style/styleMixins/offset'
import { defaultTextStyle } from '../../../style/styleMixins/text'

export const SFilterBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(2)};

  .ant-select-selector {
    height: 36px;
    width: 100%;

    border-radius: ${(p) => p.theme.decoration.borderRadius.small};
    border: 1px solid ${(p) => p.theme.colors.grey_400} !important;
    background: ${(p) => p.theme.colors.grey_000} !important;

    &:hover {
      transition: all 0.1s linear;
      border: 1px solid ${(p) => p.theme.colors.grey_500} !important;
    }
  }
`

export const STitle = styled.p`
  margin: 0;
  padding: 0;
  ${defaultTextStyle(9)}
`

export const SButtonClear = styled.div`
  display: flex;
  width: max-content;
  border-radius: ${(p) => p.theme.decoration.borderRadius.small};
  padding: ${getSpacing([0.8])};
  color: ${(p) => p.theme.colors.grey_000};
  background-color: ${(p) => p.theme.colors.grey_450};

  :hover {
    cursor: pointer;
  }
`
