import { Select } from 'antd'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { filtersAPI } from '../../../axios/axios'
import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import { SFilterBlock, STitle } from '../../atoms/Filters/Filters.styled'
import type { IFilterProps, ItemType } from '../ApplyFilters.model'

export const OrganizationFilter: FC<IFilterProps> = ({
  setFilters,
  prevState,
}) => {
  const abortControllerRef = useRef<null | AbortController>(null)
  const [data, setData] = useState<ItemType[]>([])
  const [selectedOrganization, setSelectedOrganization] =
    useState<ItemType | null>(prevState)

  const selectedOrganizationName = (selectedOrganization?.name as string) || ''

  const getOptions = useCallback(async (searchString: string) => {
    if (searchString) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
        abortControllerRef.current = null
      }

      abortControllerRef.current = new AbortController()

      try {
        const res = await filtersAPI.getOrganization(searchString, {
          signal: abortControllerRef.current?.signal,
        })
        const { data: resData } = res.data
        setData(resData)
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  const handleOnChange = useCallback(
    (e: string) => {
      if (!e) {
        setFilters(QueryKeyENUM.unitID, null)
        setSelectedOrganization(null)
      } else {
        const obj = JSON.parse(e)
        setSelectedOrganization(obj)
        setFilters(QueryKeyENUM.unitID, { ...obj, value: obj.id })
      }
    },
    [setFilters],
  )

  const options = useMemo(() => {
    if (data.length) {
      return data.map((el) => ({
        value: JSON.stringify(el),
        label: el.name,
      }))
    }
    return []
  }, [data])

  useEffect(() => {
    !prevState && setSelectedOrganization(prevState)
  }, [prevState])

  return (
    <SFilterBlock>
      <STitle>Организация</STitle>
      <Select
        defaultValue={selectedOrganizationName}
        value={selectedOrganizationName}
        allowClear
        showSearch
        defaultActiveFirstOption={false}
        filterOption={false}
        showArrow
        onSearch={getOptions}
        notFoundContent={null}
        placeholder="Начните вводить название"
        onChange={handleOnChange}
        options={options}
      />
    </SFilterBlock>
  )
}
