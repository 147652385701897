import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import type { IFilterProps, ItemType } from '../ApplyFilters.model'
import { FilterItem } from '../components/FilterItem/FilterItem'
import { SFilterBlock, SFilterList, STitle } from '../Filters.styled'

const Ages = Array(18)
  .fill(0)
  .map((_, i) => ({ name: i + 1, id: i + 1 }))

export const AgesFilter: FC<IFilterProps> = ({ setFilters, prevState }) => {
  const [selectedAges, setSelectedAges] = useState<ItemType | null>(prevState)

  const handleSetItem = useCallback(
    (item: ItemType) => {
      if (selectedAges?.id === item.id) {
        setFilters(QueryKeyENUM.age, null)
        setSelectedAges(null)
      } else {
        setSelectedAges(item)
        setFilters(QueryKeyENUM.age, { ...item, value: item.id })
      }
    },
    [selectedAges, setFilters],
  )

  const content = useMemo(
    () =>
      Ages.map((d) => (
        <FilterItem
          key={d.id}
          item={d}
          setItem={handleSetItem}
          active={selectedAges?.id === d.id}
        />
      )),
    [handleSetItem, selectedAges],
  )

  useEffect(() => {
    !prevState && setSelectedAges(prevState)
  }, [prevState])

  return (
    <SFilterBlock>
      <STitle>Возраст ребёнка</STitle>
      <SFilterList>{content}</SFilterList>
    </SFilterBlock>
  )
}
