import type { ItemType } from './ApplyFilters.model'

export enum KindTypeProgramENUM {
  CERTIFICATE = 'В рамках социального сертификата',
  FREE = 'Бесплатные',
  OFF_BUDGET = 'Платно(Внебюджетно)',
  PAID = 'Платные',
}

export enum KindTypeEventENUM {
  PAID = 'Платные',
  FREE = 'Бесплатные',
}

export interface IKind extends ItemType {
  name: KindTypeProgramENUM | KindTypeEventENUM
  type: string | number
  id: number | string
}
