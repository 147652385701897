import type { FC } from 'react'
import { useCallback } from 'react'

import { SFilterItem } from '../../Filters.styled'

interface IFilterItemProps {
  item: Record<string | number, string | number>
  setItem: (item: any) => void
  active: boolean
}

export const FilterItem: FC<IFilterItemProps> = ({ item, setItem, active }) => {
  const handleSetActive = useCallback(() => {
    setItem(item)
  }, [item, setItem])

  return (
    <SFilterItem active={active} onClick={handleSetActive}>
      {item.name}
    </SFilterItem>
  )
}
