import type { FC } from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setFiltersAction } from '../../../store/reducers/filter/filterReducer'
import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import { AgesFilter } from '../AgesFilter/AgesFilter'
import type { ItemType } from '../ApplyFilters.model'
import { DirectionsFilter } from '../DirectionsFilter/DirectionsFIlter'
import { DurationFilter } from '../DurationFilter/DurationFilter'
import { KindProgramFilter } from '../KindProgramFilter/KindProgramFilter'
import { MunicipalityFilter } from '../MunicipalityFilter/MunicipalityFilter'
import { OrganizationFilter } from '../OrganizationFilter/OrganizationFilter'
import { ProfileProgramFilter } from '../ProfileProgramFilter/ProfileProgramFilter'
import { ServicesInSocialSphere } from '../ServicesInSocialSphere/ServicesInSocialSphere'
import { FilterTypeENUM } from './Filters.model'
import { SFiltersWrapp } from './FiltersCollection.styled'

interface IProgramFiltersProps {
  selectedFilters: Record<QueryKeyENUM, ItemType>
}

export const ProgramsFilters: FC<IProgramFiltersProps> = ({
  selectedFilters,
}) => {
  const dispatch = useDispatch()

  const setFilters = useCallback(
    (key: QueryKeyENUM, item: ItemType | null) => {
      dispatch(
        setFiltersAction({
          key: FilterTypeENUM.programsFilters,
          filterKey: key,
          item,
        }),
      )
    },
    [dispatch],
  )
  return (
    <SFiltersWrapp>
      <DirectionsFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.directionId]}
      />
      <KindProgramFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.kind]}
      />
      <ProfileProgramFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.profileId]}
      />
      <MunicipalityFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.moId]}
      />
      <OrganizationFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.unitID]}
      />
      <AgesFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.age]}
      />
      <ServicesInSocialSphere
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.kind]}
      />
      <DurationFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.programDurationInHours]}
      />
    </SFiltersWrapp>
  )
}
